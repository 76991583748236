const activeClassName = 'is-active';

// Elems
const modal = document.querySelector('#modal');
const modalTitle = modal.querySelector('.modal-title');
const modalText = modal.querySelector('.modal-text');
const modalImage = modal.querySelector('.modal-image');

// Buttons
const modalPrevBtn = modal.querySelector('.modal-prev');
const modalNextBtn = modal.querySelector('.modal-next');
const modalCloseBtn = modal.querySelector('.modal-close');
const modalBackdrop = modal.querySelector('.modal-backdrop');

let callbackListeners = {};

function showModal(data) {
  modal.classList.add(activeClassName);
  modalTitle.innerText = data.title;
  modalText.innerText = data.text;
  modalImage.src = data.image || '';

  // Bind prev/next events
  modalPrevBtn.addEventListener('click', data.onPrev);
  modalNextBtn.addEventListener('click', data.onNext);
}

function hideModal() {
  modal.classList.remove(activeClassName);
  modalTitle.innerText = '';
  modalText.innerText = '';

  Object.keys(callbackListeners).forEach(callbackKey => {
    callbackListeners[callbackKey]();
  })
}

function registerModalCloseCallback(name, callback) {
  callbackListeners[name] = callback;
}

// Bind modal events
[modalCloseBtn, modalBackdrop].forEach(elem => {
  elem.addEventListener('click', hideModal);
});